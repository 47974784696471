//import 'babel-polyfill'
import 'url-search-params'
import 'dialog-polyfill'
import 'intersection-observer'
import 'details-element-polyfill'
import Stickyfill from 'stickyfilljs'
import * as smoothscroll from 'smoothscroll-polyfill'


if (!Element.prototype.matches) {
    Element.prototype.matches = 
        Element.prototype.matchesSelector || 
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector || 
        Element.prototype.oMatchesSelector || 
        Element.prototype.webkitMatchesSelector ||
        function(s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1;            
        };
}

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}
function onHandler(eventType, selector, cb) {
    let self = this;

    if (cb === undefined && typeof(selector) === 'function') {
        cb = selector;
        selector = null;
    }

    this.addEventListener(eventType, (event) => {
        if (!selector || (selector && event.target.matches(selector))) {
            cb.call(self, event);
        }
    })
}


if (window.EventTarget) {
    EventTarget.prototype.on = onHandler
}
else {
    Document.prototype.on = onHandler
    Window.prototype.on = onHandler
    Element.prototype.on = onHandler
}

window.b64encode = (str) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
}

window.b64decode = (str) => {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}

smoothscroll.polyfill()

Stickyfill.add(document.querySelectorAll('.dnjq-sticky'))

if (!window.HtmlDialogElement) {
    document.querySelectorAll('dialog').forEach(dlg => dialogPolyfill.registerDialog(dlg))
}
document.on('click', '[data-modal]', (event) => {
    event.preventDefault();
    let sel = event.target.dataset.modal;
    let dlg = document.getElementById(sel);
    dlg.showModal();
});
